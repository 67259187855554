<template>
    <div class="home">
        <div class="banner">
            <div class="banner-text">Home</div>
            <img src="@/assets/banners/home_banner.jpg"/>
        </div>
        <b-row class="home-content">
            <b-col>
                Productive job sites don't happen by accident.  Smart, forward-thinking superintendents who know how to manage and motivate people,
                solve problems, and keep an eye on the bottom line are the secrets to successful projects.  Create top-notch superintendents
                through the Carpenters International Training Fund's Superintendent Career Training.
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <h1>About</h1>
                <p>
                    The Carpenters International Training Fund's Superintendent Career Training (SCT) is an 18-month classroom and on-the-job
                    instructional program that prepares SCT participants to be highly skilled construction industry professionals.  Using
                    mentors, company sponsored learning opportunities, and expert instruction from industry experienced professors of well-known
                    universities, graduates of the SCT are equipped to oversee and manage productive work sites that keep projects on time
                    and within budget.
                </p>
                <p>
                    The concept of the SCT is based on integrating the demands and technical aspects of managing a construction job site with
                    a superintendent's personal career objectives.  To achieve that goal, each participating company can customize the
                    training according to the needs of the sponsored employee.
                </p>
                <p>
                    Potential candidates for the SCT are motivated employees who are in a supervisory position, are forward thinking and good
                    communicators, have good people skills, and show the ability to problem solve.
                </p>
            </b-col>
        </b-row>
        <b-row class="home-content clearfix pic-button">
            <b-col>
                <b-card-group deck>
                    <b-card class="about-card" title="Training" img-src="@/assets/sections/home_training.jpg" @click="picClicked('training')"/>
                    <b-card class="about-card" title="Mentors" img-src="@/assets/sections/home_mentors.jpg" @click="picClicked('mentors')"/>
                    <b-card class="about-card" title="Faculty" img-src="@/assets/sections/home_faculty.jpg" @click="picClicked('faculty')"/>
                </b-card-group>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <b-button variant="link" @click="expandAll">Expand All</b-button>
                <b-button variant="link" @click="collapseAll">Collapse All</b-button>
            </b-col>
        </b-row>
        <about-section ref="training"
                       section="home-training"
                       class="home-content"
                       title="Training"
                       :visible="trainingVisible"
                       v-on:section-clicked="trainingVisible = !trainingVisible">
            <p>
                UBC curriculum experts present the coursework based on current topics most in demand, and deliver
                lessons using theory, case studies, and hands-on learning, both in individualized and group setting.
                Training includes topics such as:
            </p>
            <ul>
                <li>Technical aspects of construction</li>
                <li>Management strategies and policy issues</li>
                <li>Computer applications</li>
                <li>Superintendent roles and responsibilities</li>
                <li>Planning and productivity</li>
                <li>Communication and leadership</li>
            </ul>
            <p>
                Theory and classroom work are completed in four sessions at the Carpenters International Training
                Center.  Structured, concentrated learning components at the Center are then matched with mentor
                sessions and on-the-job learning throughout the country.
            </p>
        </about-section>
        <about-section ref="mentors"
                      section="home-mentors"
                      class="home-content"
                      title="Mentors"
                      :visible="mentorsVisible"
                      v-on:section-clicked="mentorsVisible = !mentorsVisible">
            <p>
                Mentors are responsible for two things: Assisting the trainee in the learning process and ensuring
                that what the trainee is taught reflects the needs of the sponsoring contractor.  Mentors meet
                with their trainee 15 times over the course of the training to discuss training, instruct on a
                selected topic focused on the company's specific approach, or review strategies, policies and
                procedures.  The mentor's primary goal is to make sure the trainee gets a meaningful experience
                out of the training.
            </p>
        </about-section>
        <about-section ref="faculty"
                      section="home-faculty"
                      class="home-content"
                      title="Faculty"
                      :visible="facultyVisible"
                      v-on:section-clicked="facultyVisible = !facultyVisible">
            <p>
                The SCT faculty is from collegiate construction education programs including, but not limited to:
            </p>
            <ul>
                <li>Auburn University</li>
                <li>Northern Arizona University</li>
                <li>Washington State University</li>
                <li>Purdue University</li>
                <li>Boise State University</li>
                <li>Cal State Sacramento</li>
                <li>University of Oklahoma</li>
                <li>Arizona State University</li>
                <li>Oklahoma State University</li>
            </ul>
            <p>
                Their extensive industry work experience and expertise spans positions as superintendents, managers,
                executives, and registered engineers.  These experts will be able to work with students from a
                heightened level of understanding because they gained their experience from actual job site
                supervision and coordination.
            </p>
        </about-section>
        <b-row class="home-content clearfix">
            <b-col>
                <b-button href="/documents/brochures/SCT_Brochure_2018.pdf" class="float-right" variant="danger" target="_blank">Download Brochure</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import { Component, Vue } from 'vue-property-decorator';
import AboutSection from '@/components/public/AboutSection.vue';
import {PicClicked} from '@/util/pic_clicked';

@Component({

    mixins: [PicClicked],

    components: {
        AboutSection
    }
})
export default class Home extends Vue {

    trainingVisible = false;
    mentorsVisible = false;
    facultyVisible = false;

    expandAll() {
        this.trainingVisible = true;
        this.mentorsVisible = true;
        this.facultyVisible = true;
    }

    collapseAll() {
        this.trainingVisible = false;
        this.mentorsVisible = false;
        this.facultyVisible = false;
    }
}
</script>
<style scoped>

</style>
