<template>
    <b-row>
        <b-col>
            <div :ref="'section-' + section" :class="{always: true, fading, transition: true }">
                <h1 class="about-section" @click="$emit('section-clicked')">&raquo; {{title}}</h1>
                <b-collapse :id="section"
                            v-model="visible"
                            class="about-content"
                            @shown="scrollIntoView">
                    <slot></slot>
                </b-collapse>
            </div>
        </b-col>
    </b-row>
</template>
<script>

import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class AboutSection extends Vue {
    @Prop({type: String}) section;
    @Prop({type: String}) title;
    @Prop({type: Boolean}) visible;

    fading = false;

    scrollIntoView() {
        this.$refs['section-' + this.section].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

        return this;
    }

    fade() {
        setTimeout(() => {

            this.fading = true;

            setTimeout(() => {
                this.fading = false;
            }, 1000);

        }, this.visible ? 0 : 100);
    }
}
</script>
<style scoped>

    div.fading {
        background: #FFE;
        border-radius: 10px;
        opacity: 25%;
    }

    div.transition {
        transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -webkit-transition: all .5s
    }

</style>
